import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { Router } from '@angular/router';
import { PropertyToJson2 } from '@nest/common/core/shared';
import { DROPDOWN_IMPORTS, DROPDOWN_MENU_IMPORTS } from '@nest/uikit/dropdown/ui';
import { Entity, EntityValuePipe } from '@nest/uikit/entity-presentation/ui';
import { ICON_IMPORTS } from '@nest/uikit/icon/ui';
import { injectToaster } from '@nest/uikit/toaster/ui';
import { provideIcons } from '@ng-icons/core';
import { iconoirCopy, iconoirOpenInBrowser } from '@ng-icons/iconoir';
import clipboardCopy from 'clipboard-copy';

@Component({
	standalone: true,
	templateUrl: './sale-order-document-number-path-render.component.html',
	styles: `
		:host {
			@apply hover:underline text-blue-500 cursor-pointer;
		}
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [EntityValuePipe, DROPDOWN_IMPORTS, DROPDOWN_MENU_IMPORTS, ICON_IMPORTS],
	providers: [provideIcons({ iconoirCopy, iconoirOpenInBrowser })]
})
export class SaleOrderDocumentNumberEntityPathRendererComponent<E extends Entity> {
	toaster = injectToaster();
	router = inject(Router);

	value = input.required<string>();
	entityId = input.required<number>();
	propertyMetadata = input.required<PropertyToJson2>();

	copy() {
		clipboardCopy(this.value());
		this.toaster.success('Číslo dokladu bylo zkopírováno do schránky');
	}

	openInNewWindow() {
		this.router.navigate(['/sale-orders', 'detail', this.entityId()]);
	}
}
