import { APP_INITIALIZER, inject } from '@angular/core';
import type { SaleOrderDto, SaleOrderLineDto, SalesChannelDto } from '@nest/domain/entities-metadata/dtos';
import { SaleOrderEntityName, SaleOrderLineEntityName, SalesChannelEntityName } from '@nest/domain/entities-metadata/dtos';
import { SaleOrderDataAccessService, SaleOrderLineDataAccessService, SalesChannelDataAccessService } from '@nest/domain/erp/sale-orders/frontend/data-access';
import { EntityMetadataService, fieldsToElements } from '@nest/uikit/entity-presentation/ui';
import { autoPathsFor } from '@nest/utils/shared';
import { SaleOrderBuyerWidgetComponent, SaleOrderBuyerWidgetName } from './sale-order-buyer-widget.component';
import { SaleOrderDocumentNumberEntityPathRendererComponent } from './sale-order-document-number-path-render.component';
import { SaleOrderRecipientWidgetComponent, SaleOrderRecipientWidgetName } from './sale-order-recipient-widget.component';
import { SaleOrderStateColorEntityPathRendererComponent } from './sale-order-state-color-path-render.component';

const saleOrderPaths = autoPathsFor<SaleOrderDto>();
const saleOrderLinePaths = autoPathsFor<SaleOrderLineDto>();
const saleChannelPaths = autoPathsFor<SalesChannelDto>();

export const SaleOrderDefaultElements = fieldsToElements(
	saleOrderPaths(['createdAt', 'documentNumber', 'buyer.name', 'buyer.salesman.name', 'calculateTax', 'state', 'reservationState', 'buyerNote', 'note', 'lines'])
);
export const SaleOrderLineDefaultElements = fieldsToElements(saleOrderLinePaths(['createdAt', 'saleOrder.documentNumber', 'code', 'text', 'quantity', 'price.netto']));
export const SalesChannelDefaultElements = fieldsToElements(
	saleChannelPaths([
		'name',
		'enabled',
		'isDropshipping',
		'party.name',
		'saleOrderDocumentType.name',
		'brokerMode',
		'defaultApprovedState',
		'defaultSalesman.name',
		'deliveryPricelist.name'
	])
);

export function provideSaleOrderEntities() {
	return [
		{
			provide: APP_INITIALIZER,
			multi: true,
			useFactory: () => {
				let entityMetadata = inject(EntityMetadataService);
				let saleOrderDataAccess = inject(SaleOrderDataAccessService);
				let saleOrderLineDataAccess = inject(SaleOrderLineDataAccessService);
				let salesChannelDataAccess = inject(SalesChannelDataAccessService);

				return () => {
					entityMetadata.registerEntity<SaleOrderDto>({
						entityName: SaleOrderEntityName,
						dataAccess: saleOrderDataAccess,
						defaultElements: SaleOrderDefaultElements
					});

					entityMetadata.registerEntity<SaleOrderLineDto>({
						entityName: SaleOrderLineEntityName,
						dataAccess: saleOrderLineDataAccess,
						defaultElements: SaleOrderLineDefaultElements
					});

					entityMetadata.registerEntity<SalesChannelDto>({
						entityName: SalesChannelEntityName,
						dataAccess: salesChannelDataAccess,
						defaultElements: SalesChannelDefaultElements
					});

					entityMetadata.registerEntityPathRenderer(
						SaleOrderEntityName,
						saleOrderPaths(['state', 'fulfilmentState', 'reservationState']),
						SaleOrderStateColorEntityPathRendererComponent
					);
					entityMetadata.registerEntityPathRenderer(SaleOrderEntityName, saleOrderPaths(['documentNumber']), SaleOrderDocumentNumberEntityPathRendererComponent);

					entityMetadata.registerEntityWidget({
						entityName: SaleOrderEntityName,
						label: 'Odběratel',
						widgetName: SaleOrderBuyerWidgetName,
						fields: saleOrderPaths(['buyer.name', 'currency']),
						displayType: 'value',
						widgetComponentType: SaleOrderBuyerWidgetComponent
					});

					entityMetadata.registerEntityWidget({
						entityName: SaleOrderEntityName,
						label: 'Příjemce',
						widgetName: SaleOrderRecipientWidgetName,
						fields: saleOrderPaths(['recipientSnapshot']),
						displayType: 'value',
						widgetComponentType: SaleOrderRecipientWidgetComponent
					});
				};
			}
		}
	];
}
